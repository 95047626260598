import "core-js/modules/es.array.push.js";
import loginHead from '@/components/loginHead.vue';
import loginBottom from '@/components/loginBottom.vue';
import { validate } from '@/common/function/validate.js';
export default {
  components: {
    loginHead,
    loginBottom
  },
  data() {
    return {
      curSelect: 1,
      showPassword: false,
      mobile: '',
      user_email: '',
      code: '',
      password: '',
      areaCodeList: [],
      //区号数组
      areaCode: '',
      //选中的区号

      is_agree: false,
      //是否同意协议
      category_id: '',
      content: '',
      //协议内容

      time: 60,
      isGetCode: false,
      //是否获取验证码
      timer: '',
      //定时器

      isFlag: false
    };
  },
  mounted() {
    this.getAreaCode();
  },
  methods: {
    // 返回首页
    toHomePage() {
      this.$router.push('/home');
    },
    // 获取区号
    getAreaCode() {
      this.$http.getAreaCode().then(res => {
        if (res.code == 1) {
          this.areaCodeList = res.data;
          this.areaCode = res.data[0];
        } else {
          this.$message.error(res.data.msg);
        }
        console.log(res);
      }).catch(v => {
        console.log(v);
      });
    },
    // 区号点击
    areaCodeClick(command) {
      this.areaCode = command;
    },
    // 获取验证码
    getCode() {
      if (!this.mobile && this.curSelect == 1) {
        this.$message.error(this.$t('tips.mobilePhoneNumberCannotBeEmpty'));
      }
      if (!this.user_email && this.curSelect == 2) {
        this.$message.error(this.$t('tips.emailNumberCannotBeEmpty'));
        return;
      }
      let data = {
        type: 2,
        is_test: 0,
        mobile_code: this.areaCode
      };
      if (this.curSelect == 1) {
        this.$set(data, "mobile", this.mobile);
      } else {
        this.$set(data, "user_email", this.user_email);
      }
      this.$http.getCode(data).then(res => {
        if (res.code == 1) {
          this.$message.success(this.$t('sendSuccess'));
          this.isGetCode = true;
          this.timer = setInterval(() => {
            if (this.time == 1) {
              clearInterval(this.timer);
              this.time = 60;
              this.isGetCode = false;
            }
            this.time--;
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      }).catch(v => {});
    },
    // 确定
    confirmClick() {
      if (this.isFlag) {
        return;
      }
      if (!this.mobile && this.curSelect == 1) {
        this.$message.error(this.$t('tips.mobilePhoneNumberCannotBeEmpty'));
        return;
      }
      if (!this.user_email && this.curSelect == 2) {
        this.$message.error(this.$t('tips.emailNumberCannotBeEmpty'));
        return;
      }
      if (!this.code) {
        this.$message.error(this.$t('tips.codeCannotBeEmpty'));
        return;
      }
      if (!this.password) {
        this.$message.error(this.$t('tips.passwordCannotBeEmpty'));
        return;
      }
      let data = {
        code: this.code,
        password: this.password,
        mobile_code: this.areaCode,
        type: 2
      };
      if (this.curSelect == 1) {
        this.$set(data, "mobile", this.mobile);
      } else {
        this.$set(data, "user_email", this.user_email);
      }
      console.log('9999');
      console.log(data);
      this.$http.forgetPassword(data).then(res => {
        console.log(res);
        if (res.code == 1) {
          this.$message.success(res.msg);
          setTimeout(() => {
            this.isFlag = false;
            this.$router.go(-1);
          }, 1000);
        } else {
          this.isFlag = false;
          this.$message.error(res.msg);
        }
      }).catch(v => {});
    }
  }
};